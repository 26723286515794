<template>
  <div class="container my-5">
    <div
      v-if="onePay"
      class="space-y-3"
    >
      <div
        v-if="onePay.vpc_TxnResponseCode === '0'"
        class="text-green-600 text-xl"
      >Giao dịch thành công</div>
      <div v-else>
        <p class="text-red-600 text-xl mb-2">Giao dịch thất bại</p>
        <p class="font-bold text-base mb-1">{{ responseCode(onePay.vpc_TxnResponseCode) }}</p>
      </div>
      <div>User ID: {{onePay.user_id}}</div>
      <div>Giá tiền: {{getAmount(onePay.vpc_Amount)}}</div>
      <div>Số tài khoản: {{onePay.vpc_CardNum}}</div>
      <div>Mã giao dịch: {{onePay.vpc_MerchTxnRef}}</div>
      <div>Gói: {{onePay.vpc_OrderInfo}}</div>
    </div>
    <div v-else>
      Chưa thực hiện giao dịch
    </div>

    <div class="flex justify-center items-center">
      <button
        class="btn bg-pri text-white px-3 py-2 m-3"
        @click="goToBack"
      >
        Quay lại
      </button>
      <button
        v-if="onePay && onePay.vpc_TxnResponseCode !== '0'"
        class="btn bg-pri text-white px-3 py-2 m-3"
        @click="generateUrl"
      >
        Thanh toán lại
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { addHashQueryParam } from '@/utils/url'

export default {
  name: 'PaymentWithOnePay',
  data () {
    return {
      orderId: this.$route.params.id,
      onePay: null
    }
  },
  computed: {
    isResult () {
      return !!this.$route.query.hodo_result
    }
  },
  beforeMount () {
    if (!this.isResult) {
      this.generateUrl()
    } else if (this.$route.query.vpc_MerchTxnRef) {
      this.getTransaction(this.$route.query.vpc_MerchTxnRef)
    }
  },
  methods: {
    responseCode (code) {
      if (code === '1') {
        return 'Ngân hàng từ chối cấp phép giao dịch.'
      } if (code === '2') {
        return 'Ngân hàng phát hành thẻ từ chối cấp phép giao dịch.'
      } if (code === '3') {
        return 'Không nhận được kết quả phản hồi từ Tổ chức phát hành thẻ.'
      } if (code === '4') {
        return 'Tháng/Năm hết hạn của thẻ không đúng hoặc thẻ đã hết hạn sử dụng.'
      } if (code === '5') {
        return 'Số dư/Hạn mức của thẻ không đủ để thanh toán.'
      } if (code === '6') {
        return 'Không nhận được kết quả phản hồi từ Tổ chức phát hành thẻ.'
      } if (code === '7') {
        return 'Lỗi trong quá trình xử lý giao dịch của Ngân hàng.'
      } if (code === '8') {
        return 'Ngân hàng phát hành thẻ không hỗ trợ thanh toán trực tuyến.'
      } if (code === '9') {
        return 'Tên chủ thẻ/tài khoản không hợp lệ.'
      } if (code === '10') {
        return 'Thẻ hết hạn/Thẻ bị khóa.'
      } if (code === '11') {
        return 'Thẻ/Tài khoản chưa đăng ký dịch vụ hỗ trợ thanh toán trực tuyến.'
      } if (code === '12') {
        return 'Tháng/Năm phát hành hoặc hết hạn của thẻ không hợp lệ.'
      } if (code === '13') {
        return 'Giao dịch vượt quá hạn mức thanh toán trực tuyến theo quy định của Ngân hàng.'
      } if (code === '14') {
        return 'Số thẻ không hợp lệ. '
      } if (code === '21') {
        return 'Số dư tài khoản không đủ để thanh toán.'
      } if (code === '22') {
        return 'Thông tin tài khoản không hợp lệ.'
      } if (code === '23') {
        return 'Thẻ/Tài khoản bị khóa hoặc chưa được kích hoạt.'
      } if (code === '24') {
        return 'Thông tin thẻ/tài khoản không hợp lệ.'
      } if (code === '25') {
        return 'Mã xác thực OTP không hợp lệ.'
      } if (code === '26') {
        return 'Mã xác thực OTP đã hết hiệu lực.'
      } if (code === '98') {
        return 'Xác thực giao dịch bị hủy.'
      } if (code === '99') {
        return 'Người dùng hủy giao dịch.'
      } if (code === 'B') {
        return 'Lỗi trong quá trình xác thực giao dịch của Ngân hàng phát hành thẻ.'
      } if (code === 'D') {
        return 'Lỗi trong quá trình xác thực giao dịch của Ngân hàng phát hành thẻ.'
      } if (code === 'F') {
        return 'Xác thực giao dịch không thành công.'
      } if (code === 'U') {
        return 'Xác thực mã CSC không thành công.'
      } if (code === 'Z') {
        return 'Giao dịch bị từ chối.'
      } if (code === '253') {
        return 'Hết thời hạn nhập thông tin thanh toán.'
      } if (code === '') {
        return 'Lỗi không xác định.'
      }
    },
    async generateUrl () {
      const href = location.origin + '/#' + this.$route.path
      const res = await axios({
        url: 'https://staging.hodo.com.vn/api/node/onepay/generate-url',
        method: 'POST',
        data: {
          orderType: 1,
          orderId: parseInt(this.orderId),
          returnUrl: addHashQueryParam(href, 'hodo_result', '1')
        }
      })
      location.replace(res.data.url)
      console.log('res', res.data)
    },
    async getTransaction (txnId) {
      const res = await axios({
        url: `https://staging.hodo.com.vn/api/node/onepay/${txnId}`,
        method: 'GET'
      })
      console.log('res', res)
      if (res.data?.vpc_TxnResponseCode === '0') {
        window.$toast.open({
          message: 'Giao dịch thành công',
          type: 'success'
        })
      } else {
        window.$toast.open({
          message: 'Giao dịch thất bại',
          type: 'error'
        })
      }
      this.onePay = res.data
    },
    getAmount (amount) {
      return amount && this.numberFormat(Number(amount.slice(0, -2)))
    },
    numberFormat (number) {
      return number.toLocaleString(undefined, { minimumFractionDigits: 0 })
    },
    goToBack () {
      this.$router.push({ path: '/hssk' })
    }
  }
}
</script>
